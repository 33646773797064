body, html
{
	width: 97vw;
    height: calc(100vh - 16px);
}

body
{
  color: white;
	display: flex;
  	align-items: center;
  	justify-content: center;
	background-color: rgb(41, 41, 41);
}

#root:not(:empty) + .loader {
  display: none;
}

.loader
{
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background: linear-gradient(to bottom,black 0%,black 50%,white 50%,white 100%);
	display: block;
	box-shadow: 6px 6px 27px 4px rgba(0,0,0,0.16);
	display: flex;
  	align-items: center;
  	justify-content: center;
	transform: rotate(90deg);
	animation: 5s rotate linear infinite;
}

@media only screen and (max-width: 700px) {
	.loader
{
	width: 100px !important;
	height: 100px !important;
}}

.loader.smaller {
	width: 140px !important;
	height: 140px !important;
	margin-bottom: 30px;
}

.loader:after, .loader:before
{
	content: '';
	display: block;	
	border-radius: 50%;
}

.loader:after
{
	width: 50%;
	height: 50%;
	background: radial-gradient(ellipse at center, white 0%, white 25%, black 25%, black 100%);
	animation: inner-size 3s ease-in-out infinite;
	animation-delay: -1.5s;
}

.loader:before
{
	width: 50%;
	height: 50%;
	background: radial-gradient(ellipse at center, black 0%, black 25%, white 25%, white 100%);
	animation: inner-size 3s ease-in-out infinite;
}

@keyframes inner-size {
	0%, 100% {
		width: 50%;
		height: 50%;
	}
	
	50% {
		width: 50%;
		height: 50%;
	}	
}

@keyframes rotate {
	to {transform: rotate(360deg)}
}

.not_found {
	display: flex !important;
    flex-direction: column;
	align-items: center !important;
}

.not_found.text {
	text-align: center;
	position: -webkit-sticky !important;
    position: sticky !important;
}