// @font-face {
//   font-family: 'Noto Sans SC', sans-serif;
//   src: url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC&family');
//   unicode-range: U+4E00-9FFF;
// }

// @font-face {
//   font-family: 'Rubik', sans-serif;
//   src: url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
// }

$border-radius: 20px;
$primary: #d90202;
$background: rgb(41, 41, 41);
$base: #390707;
$shadow-color:  #838383;
$lighter-shadow: rgba($shadow-color, .7);
$gray: #8c8c8c;
$lighter-gray: rgba($gray, .1);
$transition: .3s all ease;

@media only screen and (max-width: 700px) {
body {
  // font-family: 'Noto Sans SC', 'Rubik', sans-serif;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $background;
  .card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97vw;
    height: calc(100vh - 16px);
    padding: 0px;
    border-radius: $border-radius;
    box-shadow: 0px 0px 70px 0px $shadow-color;
    background: black;
    overflow: hidden;
    .current-track{
      padding: 0px 0px;
      border-radius: $border-radius;
      width: inherit;
      background-color: #1a1a1a;
      .control-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text-wrap{
          position: relative;
          width: 100%;
          height: 49vh;
          text-align: center;
          overflow-y: scroll;
          border-radius: 20px;
          box-shadow: 0px 10px 40px 0px $lighter-shadow;
          background-color: black;
        }
        .text-card-chinese{
          font-size: 20pt;
          padding: 10px;
        }
        .text-card-english{
          padding: 10px;
        }
        .text-card-info{
          text-align: left;
          padding: 10px;
        }
        .text-card-settings{
          padding: 10px;
          h2{
            text-align:center;
            padding:8px;
            border: 1px solid #d90202;
            border-radius:15px;
          }
          .item-switch-container{
            display: flex;
          }
          .setting-text{
            text-align: left;
            padding: 15px 0px 15px 15px;
            font-size:10pt;
            flex: 1.4;
            max-width: 70%;
          }
          .setting-button{
            flex: 0.6;
            padding: 15px 0px;
          }
          .install-button{
            display: flex;
            flex: 0.6;
            display: inline-block;
            cursor: pointer;
            width: 70px;
            height: 30px;
            position: relative;
          }
        }
        .time{
          display: flex;
          margin-top: 15px;
          justify-content: space-between;
          width: 80%;
          .playing-animation {
            width: 2rem;
            height: 1.5rem;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 5px;
            box-sizing: border-box;
          }
          .playing__bar {
            background: $primary;
            width: 30%;
            animation: up-and-down 1.3s ease infinite alternate;
          }
          .playing__bar1 {
            height: 60%;
          }
          .playing__bar2 {
            height: 30%;
            animation-delay: -2.2s;
          }
          .playing__bar3 {
            height: 75%;
            animation-delay: -3.7s;
          }
          @keyframes up-and-down {
            10% {
              height: 30%;
            }        
            30% {
              height: 100%;
            }        
            60% {
              height: 50%;
            }        
            80% {
              height: 75%;
            }        
            100% {
              height: 60%;
            }
          }
        }
        #timeline{
          margin-top: 3px;
          position: relative;
          width: 80%;
          height: 5px;
          background: $primary;
          border-radius: 5px;
          &:hover{
            .hover-playhead{
               opacity: 1;
              &::before{
                opacity: 1;
              }
              &::after{
                opacity: 1;
              }
            }
          }
          #playhead{
            position: relative;
            z-index: 2;
            width: 0;
            height: 5px;
            border-radius: 5px;
            background: $base;
          }
          .hover-playhead{
            position: absolute;
            z-index: 1;
            top: 0;
            width: 0;
            height: 5px;
            opacity: 0;
            border-radius: 5px;
            background: $shadow-color;
            transition: opacity .3s;
            &::before{
              opacity: 0;
              content: attr(data-content);
              display: block;
              position: absolute;
              top: -30px;
              right: -23px;
              width: 40px;
              padding: 3px;
              text-align: center;
              background: $shadow-color;
              border-radius: calc( #{$border-radius} - 12px);
            }
            &::after{
              opacity: 0;
              content:'';
              display: block;
              position: absolute;
              top: -8px;
              right: -8px;
              border-top: 8px solid $shadow-color;
              border-left:8px solid transparent;
              border-right:8px solid transparent;
            }
          }
        }
        .controls{
          margin: 12px 0px;
          button{
            // color: white;
            border-radius: 50%;
            margin: 8px 20px;
            margin: 8px 3vw;
            text-align: center;
            transition: 0.2s;
            background: 0;
            border: none;
            &:focus{
              outline: none;
            }
            &.play{
              width: 44px;
              height: 44px;
              border: 1px solid #e2e2e2;
              background-image: url("../img/play.svg");
              &:hover{
                left: 0;
                box-shadow: 0px 0px 15px 0px  $lighter-shadow;
              }
            }
            &.pause{
                width: 44px;
                height: 44px;
                border: 1px solid #e2e2e2;
                background-image: url("../img/pause.svg");
                &:hover{
                  left: 0;
                  box-shadow: 0px 0px 15px 0px  $lighter-shadow;
              }
            }
            &.back-5{
              width: 34px;
              height: 34px;
              background-image: url("../img/back-5.svg");
              &:active{
                transform: scale(1.1);
              }
            }
            &.forward-5{
              width: 34px;
              height: 34px;
              background-image: url("../img/forward-5.svg");
              &:active{
                transform: scale(1.1);
              }
            }
            &.info{
              width: 34px;
              height: 34px;
              background-image: url("../img/info.svg");
              &:active{
                transform: scale(1.1);
              }
            }
            &.settings{
              width: 32px;
              height: 32px;
              background-image: url("../img/settings.svg");
              &:active{
                transform: scale(1.1);
              }
            }
            &.prev{
              width: 40px;
              height: 40px;
              background-image: url("../img/rewind.svg");
              &:active{
                transform: scale(1.1);
              }
            }
            &.next{
              width: 40px;
              height: 40px;
              background-image: url("../img/fforward.svg");
              &:active{
                transform: scale(1.1);
              }
            }
  
          }
        }
      }
    }
    .play-list{
      padding: 10px;
      // height: 90vh;
      // height: 260px;
      overflow-y: scroll;
      width: inherit;
      align-items: center;
      .track{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: calc( #{$border-radius} - 10px);
        border: 1px solid transparent;
        transition: 0.3s;
        width: 70vw;
        // cursor: pointer;
        margin: 0 auto 10px auto;
        &:hover{
          background: $shadow-color;
          border-color: $shadow-color;
          position: relative;
        }
        &.current-audio{
          background: #3d3d3d;;
          box-shadow: 0px 0px 15px 0px #3d3d3d;
        }
        &.play-now{
          background: #3d3d3d;;
          box-shadow: 0px 0px 15px 0px  #3d3d3d;
      }
      .track-info-container{
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        .track-name{
          font-size: 17px;
          margin: 5px 0px;
          line-height: 22px;
        }
      }
      .track-duration{
        font-weight: 500;
        margin-right: 15px;
      }
      }
    }
  }
}
}



@media only screen and (min-width: 700px) {
  body {
    // font-family: 'Noto Sans SC', 'Rubik', sans-serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $background;
    .card{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80vw;
      height: calc(100vh - 48px);
      padding: 6px;
      margin: 10px 0px;
      border-radius: $border-radius;
      box-shadow: 0px 0px 70px 0px $shadow-color;
      background: black;
      overflow: hidden;
        .current-track{
          padding: 0px 0px;
          border-radius: $border-radius;
          width: inherit;
          background-color: #1a1a1a;
          .control-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .text-wrap{
              position: relative;
              width: 100%;
              height: 49vh;
              text-align: center;
              overflow-y: scroll;
              border-radius: 20px;
              box-shadow: 0px 10px 40px 0px $lighter-shadow;
              background-color: black;
            }
            .text-card-chinese{
              font-size: 20pt;
              padding: 10px;
            }
            .text-card-english{
              padding: 10px;
            }
            .text-card-info{
              text-align: left;
              padding: 10px;
            }
            .text-card-settings{
              padding: 10px;
              h2{
                text-align:center; 
                padding:8px; 
                border: 1px solid #d90202; 
                border-radius:15px;
              } 
              .item-switch-container{
                display: flex;
              }
              .setting-text{
                text-align: left;
                padding: 15px 0px 15px 15px;
                font-size:10pt;
                flex: 1.4;
                max-width: 70%;
              }
              .setting-button{
                flex: 0.6;
                padding: 15px 0px;
                              }
              .setting-en-flag{
                width: 50px;
                height: 25px;
                border: 1px solid #e2e2e2;
                background-image: url("../img/en.svg");
                display: inline-block;
                flex-wrap: wrap; 
                cursor: pointer;
                border-radius: 2px;
                &:hover{
                  left: 0;
                  box-shadow: 0px 0px 15px 0px  $lighter-shadow;
              }}
              .setting-cn-flag{
                width: 50px;
                height: 25px;
                border: 1px solid #e2e2e2;
                background-image: url("../img/cn.svg");
                display: inline-block;
                flex-wrap: wrap; 
                cursor: pointer;
                border-radius: 2px;
                &:hover{
                  left: 0;
                  box-shadow: 0px 0px 15px 0px  $lighter-shadow;
              }}
              .install-button{
                display: flex;
                flex: 0.6;
                display: inline-block;
                cursor: pointer;
                width: 70px;
                height: 30px;
                position: relative;
              }
            }
            .time{
              display: flex;
              margin-top: 15px;
              justify-content: space-between;
              width: 80%;
              .playing-animation {
                width: 2rem;
                height: 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                padding: 5px;
                box-sizing: border-box;
              }
              .playing__bar {
                background: $primary;
                width: 30%;
                animation: up-and-down 1.3s ease infinite alternate;
              }
              .playing__bar1 {
                height: 60%;
              }
              .playing__bar2 {
                height: 30%;
                animation-delay: -2.2s;
              }
              .playing__bar3 {
                height: 75%;
                animation-delay: -3.7s;
              }
              @keyframes up-and-down {
                10% {
                  height: 30%;
                }        
                30% {
                  height: 100%;
                }        
                60% {
                  height: 50%;
                }        
                80% {
                  height: 75%;
                }        
                100% {
                  height: 60%;
                }
              }
            }
            #timeline{
              margin-top: 3px;
              position: relative;
              width: 80%;
              height: 5px;
              background: $primary;
              border-radius: 5px;
              &:hover{
                .hover-playhead{
                   opacity: 1;
                  &::before{
                    opacity: 1;
                  }
                  &::after{
                    opacity: 1;
                  }
                }
              }
              #playhead{
                position: relative;
                z-index: 2;
                width: 0;
                height: 5px;
                border-radius: 5px;
                background: $base;
              }
              .hover-playhead{
                position: absolute;
                z-index: 1;
                top: 0;
                width: 0;
                height: 5px;
                opacity: 0;
                border-radius: 5px;
                background: $shadow-color;
                transition: opacity .3s;
                &::before{
                  opacity: 0;
                  content: attr(data-content);
                  display: block;
                  position: absolute;
                  top: -30px;
                  right: -23px;
                  width: 40px;
                  padding: 3px;
                  text-align: center;
                  background: $shadow-color;
                  border-radius: calc( #{$border-radius} - 12px);
                }
                &::after{
                  opacity: 0;
                  content:'';
                  display: block;
                  position: absolute;
                  top: -8px;
                  right: -8px;
                  border-top: 8px solid $shadow-color;
                  border-left:8px solid transparent;
                  border-right:8px solid transparent;
                }
              }
            }
            .controls{
              margin: 12px 0px;
              button{
                // color: white;
                border-radius: 50%;
                margin: 8px 20px;
                margin: 8px 3vw;
                text-align: center;
                transition: 0.2s;
                background: 0;
                border: none;
                &:focus{
                  outline: none;
                }
                &.play{
                  width: 44px;
                  height: 44px;
                  border: 1px solid #e2e2e2;
                  background-image: url("../img/play.svg");
                  &:hover{
                    left: 0;
                    box-shadow: 0px 0px 15px 0px  $lighter-shadow;
                  }
                }
                &.pause{
                    width: 44px;
                    height: 44px;
                    border: 1px solid #e2e2e2;
                    background-image: url("../img/pause.svg");
                    &:hover{
                      left: 0;
                      box-shadow: 0px 0px 15px 0px  $lighter-shadow;
                  }
                }
                &.back-5{
                  width: 34px;
                  height: 34px;
                  background-image: url("../img/back-5.svg");
                  &:active{
                    transform: scale(1.1);
                  }
                }
                &.forward-5{
                  width: 34px;
                  height: 34px;
                  background-image: url("../img/forward-5.svg");
                  &:active{
                    transform: scale(1.1);
                  }
                }
                &.info{
                  width: 34px;
                  height: 34px;
                  background-image: url("../img/info.svg");
                  &:active{
                    transform: scale(1.1);
                  }
                }
                &.settings{
                  width: 32px;
                  height: 32px;
                  background-image: url("../img/settings.svg");
                  &:active{
                    transform: scale(1.1);
                  }
                }
                &.prev{
                  width: 40px;
                  height: 40px;
                  background-image: url("../img/rewind.svg");
                  &:active{
                    transform: scale(1.1);
                  }
                }
                &.next{
                  width: 40px;
                  height: 40px;
                  background-image: url("../img/fforward.svg");
                  &:active{
                    transform: scale(1.1);
                  }
                }
      
              }
            }
          }
      }
      .play-list{
        padding: 10px;
        overflow-y: scroll;
        width: inherit;
        align-items: center;
        .track{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: calc( #{$border-radius} - 10px);
          border: 1px solid transparent;
          transition: 0.3s;
          width: 40vw;
          margin: 0 auto 10px auto;
          &:hover{
            background: $shadow-color;
            border-color: $shadow-color;
            position: relative;
          }
          &.current-audio{
            background: #3d3d3d;;
            box-shadow: 0px 0px 15px 0px #3d3d3d;
          }
          &.play-now{
            background: #3d3d3d;;
            box-shadow: 0px 0px 15px 0px  #3d3d3d;
        }
        .track-info-container{
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          .track-name{
            font-size: 17px;
            margin: 5px 0px;
            line-height: 22px;
          }
        }
        .track-duration{
          font-weight: 500;
          margin-right: 15px;
        }
        }
      }
    }
  }
  }

@media only screen and (min-width: 1024px) {
  body {
    // font-family: 'Noto Sans SC', 'Rubik', sans-serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $background;
    .card{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50vw;
      height: calc(100vh - 76px);
      padding: 10px;
      margin: 20px 0px;
      border-radius: $border-radius;
      box-shadow: 0px 0px 70px 0px $shadow-color;
      background: black;
      overflow: hidden;
      .current-track{
        padding: 0px 0px;
        border-radius: $border-radius;
        width: inherit;
        background-color: #1a1a1a;
        .control-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .text-wrap{
            position: relative;
            width: 100%;
            height: 49vh;
            text-align: center;
            overflow-y: scroll;
            border-radius: 20px;
            box-shadow: 0px 10px 40px 0px $lighter-shadow;
            background-color: black;
          }
          .text-card-chinese{
            font-size: 20pt;
            padding: 10px;
          }
          .text-card-english{
            padding: 10px;
          }
          .text-card-info{
            text-align: left;
            padding: 10px;
          }
          .text-card-settings{
            padding: 10px;
            h2{
              text-align:center; 
              padding:8px; 
              border: 1px solid #d90202; 
              border-radius:15px;
            } 
            .item-switch-container{
              display: flex;
            }
            .setting-text{
              text-align: left;
              padding: 15px 0px 15px 15px;
              font-size:10pt;
              flex: 1.4;
              max-width: 70%;
            }
            .setting-button{
              flex: 0.6;
              padding: 15px 0px;
            }
            .install-button{
              display: flex;
              flex: 0.6;
              display: inline-block;
              cursor: pointer;
              width: 70px;
              height: 30px;
              position: relative;
            }
          }
          .time{
            display: flex;
            margin-top: 15px;
            justify-content: space-between;
            width: 80%;
            .playing-animation {
              width: 2rem;
              height: 1.5rem;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              padding: 5px;
              box-sizing: border-box;
            }
            .playing__bar {
              background: $primary;
              width: 30%;
              animation: up-and-down 1.3s ease infinite alternate;
            }
            .playing__bar1 {
              height: 60%;
            }
            .playing__bar2 {
              height: 30%;
              animation-delay: -2.2s;
            }
            .playing__bar3 {
              height: 75%;
              animation-delay: -3.7s;
            }
            @keyframes up-and-down {
              10% {
                height: 30%;
              }        
              30% {
                height: 100%;
              }        
              60% {
                height: 50%;
              }        
              80% {
                height: 75%;
              }        
              100% {
                height: 60%;
              }
            }
          }
          #timeline{
            margin-top: 3px;
            position: relative;
            width: 80%;
            height: 5px;
            background: $primary;
            border-radius: 5px;
            &:hover{
              .hover-playhead{
                  opacity: 1;
                &::before{
                  opacity: 1;
                }
                &::after{
                  opacity: 1;
                }
              }
            }
            #playhead{
              position: relative;
              z-index: 2;
              width: 0;
              height: 5px;
              border-radius: 5px;
              background: $base;
            }
            .hover-playhead{
              position: absolute;
              z-index: 1;
              top: 0;
              width: 0;
              height: 5px;
              opacity: 0;
              border-radius: 5px;
              background: $shadow-color;
              transition: opacity .3s;
              &::before{
                opacity: 0;
                content: attr(data-content);
                display: block;
                position: absolute;
                top: -30px;
                right: -23px;
                width: 40px;
                padding: 3px;
                text-align: center;
                background: $shadow-color;
                border-radius: calc( #{$border-radius} - 12px);
              }
              &::after{
                opacity: 0;
                content:'';
                display: block;
                position: absolute;
                top: -8px;
                right: -8px;
                border-top: 8px solid $shadow-color;
                border-left:8px solid transparent;
                border-right:8px solid transparent;
              }
            }
          }
          .controls{
            margin: 12px 0px;
            button{
              border-radius: 50%;
              margin: 8px 20px;
              margin: 8px 3vw;
              text-align: center;
              transition: 0.2s;
              background: 0;
              border: none;
              &:focus{
                outline: none;
              }
              &.play{
                width: 44px;
                height: 44px;
                border: 1px solid #e2e2e2;
                background-image: url("../img/play.svg");
                &:hover{
                  left: 0;
                  box-shadow: 0px 0px 15px 0px  $lighter-shadow;
                }
              }
              &.pause{
                  width: 44px;
                  height: 44px;
                  border: 1px solid #e2e2e2;
                  background-image: url("../img/pause.svg");
                  &:hover{
                    left: 0;
                    box-shadow: 0px 0px 15px 0px  $lighter-shadow;
                }
              }
              &.back-5{
                width: 34px;
                height: 34px;
                background-image: url("../img/back-5.svg");
                &:active{
                  transform: scale(1.1);
                }
              }
              &.forward-5{
                width: 34px;
                height: 34px;
                background-image: url("../img/forward-5.svg");
                &:active{
                  transform: scale(1.1);
                }
              }
              &.info{
                width: 34px;
                height: 34px;
                background-image: url("../img/info.svg");
                &:active{
                  transform: scale(1.1);
                }
              }
              &.settings{
                width: 32px;
                height: 32px;
                background-image: url("../img/settings.svg");
                &:active{
                  transform: scale(1.1);
                }
              }
              &.prev{
                width: 40px;
                height: 40px;
                background-image: url("../img/rewind.svg");
                &:active{
                  transform: scale(1.1);
                }
              }
              &.next{
                width: 40px;
                height: 40px;
                background-image: url("../img/fforward.svg");
                &:active{
                  transform: scale(1.1);
                }
              }
    
            }
          }
        }
      }
      .play-list{
        padding: 10px;
        overflow-y: scroll;
        width: inherit;
        align-items: center;
        .track{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: calc( #{$border-radius} - 10px);
          border: 1px solid transparent;
          transition: 0.3s;
          width: 20vw;
          margin: 0 auto 10px auto;
          &:hover{
            background: $shadow-color;
            border-color: $shadow-color;
            position: relative;
          }
          &.current-audio{
            background: #3d3d3d;;
            box-shadow: 0px 0px 15px 0px #3d3d3d;
          }
          &.play-now{
            background: #3d3d3d;;
            box-shadow: 0px 0px 15px 0px  #3d3d3d;
        }
        .track-info-container{
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          .track-name{
            font-size: 17px;
            margin: 5px 0px;
            line-height: 22px;
          }
        }
        .track-duration{
          font-weight: 500;
          margin-right: 15px;
        }
        }
      }
    }
  }
  }


.text-wrap::-webkit-scrollbar {
  width: 5px;
}

.play-list::-webkit-scrollbar {
    width: 5px;
}

.play-list::-webkit-scrollbar-thumb {
  background: white; 
  border-radius: 5px;
}

.play-list::-webkit-scrollbar-track {
  background: $base; 
}


@keyframes play {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
